document.addEventListener('DOMContentLoaded', function() {
    InitializeSlider()
    DrawIcon()
})

// home swiper

function InitializeSlider() {
    const swiperHero = new Swiper('.swiper-hero', {
        direction: "horizontal",
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        breakpoints: {
            991: {
                direction: "vertical",
                effect: "slide",
                grabCursor: true,
            }
        }
    })
}

// icon animation

function DrawIcon() {
    const svg = document.querySelector('.scroll-icon');

    if (svg) {
        // Add event listener if the element exists
        svg.addEventListener('click', function() {
            window.scrollBy({
                top: window.innerHeight * 0.9, // 90% of the viewport height
                left: 0,
                behavior: 'smooth'
            });
        });
    } else {
    }

    setTimeout(() => {
        svg.classList.add('active');
    }, 600)
}
